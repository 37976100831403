import { oauthRedicetUri, keycloak, importIpcRenderer, ipcRenderer } from './config/PlatformConfig'

importIpcRenderer()

const token: any = localStorage.getItem('access_token')
const refreshToken: any = localStorage.getItem('refresh_token')

/** Setting electron window background to transparent color */
if (ipcRenderer) {
  ipcRenderer.invoke('SET_BACKGROUND_COLOR', '#00000000')
}

keycloak.init({
  onLoad: 'login-required',
  token,
  refreshToken,
  checkLoginIframe: false,
  redirectUri: oauthRedicetUri()
}).then((auth: any) => {
  if (!auth) {
    // localStorage.removeItem('access_token')
    // localStorage.removeItem('refresh_token')
    /** Clearing localstorage to remove existing login data including matrix token. */
    /** TODO: Check if we need to keep any data. */
    localStorage.clear()
    window.location.reload()
  } else {
    localStorage.setItem('access_token', keycloak.token!)
    localStorage.setItem('refresh_token', keycloak.refreshToken!)

    /** Refresh token */
    const tokenRenewal = async () => {
      const minValidity = 60 * 60 // 1 hour
      if (!keycloak.authenticated) return

      if (keycloak.tokenParsed && keycloak.tokenParsed.exp) {
        const tokenValidity = keycloak.tokenParsed.exp - Math.ceil(Date.now() / 1000)
        if (tokenValidity < minValidity) {
          await keycloak.updateToken(70)
        }
      }
    }
    start()

    // Start renewal checks
    const interval = setInterval(tokenRenewal, 10000)
    return () => clearInterval(interval)
  }
}).catch((e) => {
  console.log('Authentication Failed', e)
  // localStorage.removeItem('access_token')
  // localStorage.removeItem('refresh_token')
  localStorage.clear()
  window.location.reload()
})

keycloak.onTokenExpired = () => {
  keycloak.updateToken(70).then((auth: any) => {
    if (!auth) {
      // localStorage.removeItem('access_token')
      // localStorage.removeItem('refresh_token')
      localStorage.clear()
      window.location.reload()
    } else {
      localStorage.setItem('access_token', keycloak.token!)
      localStorage.setItem('refresh_token', keycloak.refreshToken!)
      start()
    }
  })
}

async function start () {
  // if (!isWebPlatform()) {
    const loadOlm = await import('./initOlm')
    const loadMatrix = await import('./initMatrix')
    await loadOlm.loadOlm()
    await loadMatrix.login()
    const loadApp = await import('./bloxApp')
    await loadApp
  // } else {
  //   const loadApp = await import('./bloxAppDownload')
  //   await loadApp
  // }
}
