import Keycloak from 'keycloak-js'

const initOptions: any = {
  url: process.env.VUE_APP_KEYCLOAK_AUTH_URL,
  realm: process.env.VUE_APP_KEYCLOAK_REALM,
  clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
  onLoad: 'login-required'
}
const keycloak = Keycloak(initOptions)

function oauthRedicetUri (): string {
  return !isWebPlatform() && process.env.NODE_ENV === 'production'
  ? 'app://./index.html' : window.location.origin
}

function isWebPlatform (): boolean {
  return !['darwin', 'win32', 'linux'].includes(process.platform)
}

let ipcRenderer: any = null

/** Import ipcRenderer only in electron app. */
function importIpcRenderer() {
  if (!isWebPlatform()) {
    import('electron').then((electron) => {
      ipcRenderer = electron.ipcRenderer
    }).catch((error) => {
      console.error('Failed to load electron module: ', error)
    })
  }
}

let setupScreenSharingRender
let initPopupsConfigurationRender
let setupPowerMonitorRender

function importJitsiScreenshareModules() {
  if (!isWebPlatform()) {
    import('@jitsi/electron-sdk').then((jitsi) => {
      setupScreenSharingRender = jitsi.setupScreenSharingRender
      initPopupsConfigurationRender = jitsi.initPopupsConfigurationRender
      setupPowerMonitorRender = jitsi.setupPowerMonitorRender
    }).catch((error) => {
      console.error('Failed to load jitsi electron module: ', error)
    })
  }
}

function isUserAgentMobile() {
  const userAgent = navigator.userAgent
  const mobileRegex = /Mobi/i
  const isMobile = mobileRegex.test(userAgent)
  return isMobile
}

export {
  isWebPlatform,
  oauthRedicetUri,
  keycloak,
  importIpcRenderer,
  ipcRenderer,
  isUserAgentMobile,
  importJitsiScreenshareModules,
  setupScreenSharingRender,
  initPopupsConfigurationRender,
  setupPowerMonitorRender
}
